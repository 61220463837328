export const COLORS = {
  BASE_WHITE: '#ffffff',
  BASE_BLACK: '#000000',
  GRAY_25: '#fcfcfd',
  GRAY_50: '#f9fafb',
  GRAY_100: '#f2f4f7',
  GRAY_200: '#eaecf0',
  GRAY_300: '#d0d5dd',
  GRAY_400: '#98a2b3',
  GRAY_500: '#667085',
  GRAY_600: '#475467',
  GRAY_700: '#344054',
  GRAY_900: '#101828',
  GRAY_800: '#1d2939',
  PRIMARY_25: '#f3fafa',
  PRIMARY_50: '#e9f5f5',
  PRIMARY_100: '#d6eaeb',
  PRIMARY_300: '#93c6c9',
  PRIMARY_400: '#75b7bb',
  PRIMARY_200: '#badbdc',
  PRIMARY_500: '#47a2a9',
  PRIMARY_600: '#07939c',
  PRIMARY_700: '#07757c',
  PRIMARY_800: '#075d63',
  PRIMARY_900: '#064f54',
  ERROR_25: '#fdf8f7',
  ERROR_50: '#fef1ef',
  ERROR_100: '#ffe4de',
  ERROR_200: '#ffd0c6',
  ERROR_400: '#fc8474',
  ERROR_500: '#f04c44',
  ERROR_300: '#ffb5a7',
  ERROR_600: '#d3413a',
  ERROR_700: '#b73731',
  ERROR_800: '#8f2824',
  ERROR_900: '#7b211d',
  WARNING_25: '#fefaf3',
  WARNING_50: '#fcf6ea',
  WARNING_100: '#f9ead1',
  WARNING_200: '#f4d6a6',
  WARNING_300: '#edb86d',
  WARNING_400: '#eaab55',
  WARNING_500: '#e69934',
  WARNING_600: '#bf7828',
  WARNING_700: '#a96721',
  WARNING_800: '#884d17',
  WARNING_900: '#67360d',
  SUCCESS_25: '#f7fcfa',
  SUCCESS_50: '#eff9f3',
  SUCCESS_100: '#def3e6',
  SUCCESS_200: '#c4ead2',
  SUCCESS_300: '#aae0be',
  SUCCESS_400: '#7cd09d',
  SUCCESS_500: '#52c284',
  SUCCESS_600: '#24b871',
  SUCCESS_700: '#1b8f58',
  SUCCESS_800: '#136840',
  SUCCESS_900: '#0e4f31',
  SECONDARY_25: '#fafdf7',
  SECONDARY_50: '#f5fbee',
  SECONDARY_100: '#e6f4d7',
  SECONDARY_300: '#acdc79',
  SECONDARY_200: '#ceeab0',
  SECONDARY_500: '#669f2a',
  SECONDARY_400: '#86cb3c',
  SECONDARY_600: '#4f7a21',
  SECONDARY_700: '#3f621a',
  SECONDARY_800: '#335015',
  SECONDARY_900: '#2b4212',
  INFO_25: '#f5faff',
  INFO_50: '#eff8ff',
  INFO_100: '#d1e9ff',
  INFO_200: '#b2ddff',
  INFO_300: '#84caff',
  INFO_400: '#53b1fd',
  INFO_500: '#2e90fa',
  INFO_600: '#1570ef',
  INFO_700: '#175cd3',
  INFO_800: '#1849a9',
  INFO_900: '#194185',
  VIOLET_25: '#fbfaff',
  VIOLET_50: '#f5f3ff',
  VIOLET_100: '#ece9fe',
  VIOLET_200: '#ddd6fe',
  VIOLET_300: '#c3b5fd',
  VIOLET_400: '#a48afb',
  VIOLET_500: '#875bf7',
  VIOLET_600: '#7839ee',
  VIOLET_700: '#6927da',
  VIOLET_800: '#5720b7',
  VIOLET_900: '#491c96',
  YELLOW_25: '#fefdf0',
  YELLOW_50: '#fefbe8',
  YELLOW_100: '#fef7c3',
  YELLOW_200: '#feee95',
  YELLOW_300: '#fde272',
  YELLOW_400: '#fac515',
  YELLOW_500: '#eaaa08',
  YELLOW_600: '#ca8504',
  YELLOW_700: '#a15c07',
  YELLOW_800: '#854a0e',
  YELLOW_900: '#713b12',
}
