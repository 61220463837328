export const TEXT = {
  DISPLAY_2_XL_BOLD: {
    fontFamily: 'Inter',
    fontSize: 72,
    fontWeight: 'bold',
    letterSpacing: -1.44,
  },
  DISPLAY_2_XL_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 72,
    fontWeight: '600',
    letterSpacing: -1.44,
  },
  DISPLAY_2_XL_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 72,
    fontWeight: '500',
    letterSpacing: -1.44,
  },
  DISPLAY_2_XL_REGULAR: {
    fontFamily: 'Inter',
    fontSize: 72,
    fontWeight: 'normal',
    letterSpacing: -1.44,
  },
  DISPLAY_XL_BOLD: {
    fontFamily: 'Inter',
    fontSize: 60,
    fontWeight: 'bold',
    letterSpacing: -1.2,
  },
  DISPLAY_XL_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 60,
    fontWeight: '600',
    letterSpacing: -1.2,
  },
  DISPLAY_XL_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 60,
    fontWeight: '500',
    letterSpacing: -1.2,
  },
  DISPLAY_XL_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 60,
    fontWeight: 'normal',
    letterSpacing: -1.2,
  },
  DISPLAY_LG_BOLD: {
    fontFamily: 'Inter',
    fontSize: 48,
    fontWeight: 'bold',
    letterSpacing: -0.96,
  },
  DISPLAY_LG_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 48,
    fontWeight: '600',
    letterSpacing: -0.96,
  },
  DISPLAY_LG_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 48,
    fontWeight: '500',
    letterSpacing: -0.96,
  },
  DISPLAY_LG_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 48,
    fontWeight: 'normal',
    letterSpacing: -0.96,
  },
  DISPLAY_MD_BOLD: {
    fontFamily: 'Inter',
    fontSize: 36,
    fontWeight: 'bold',
    letterSpacing: -0.72,
  },
  DISPLAY_MD_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 36,
    fontWeight: '600',
    letterSpacing: -0.72,
  },
  DISPLAY_MD_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 36,
    fontWeight: '500',
    letterSpacing: -0.72,
  },
  DISPLAY_MD_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 36,
    fontWeight: 'normal',
    letterSpacing: -0.72,
  },
  DISPLAY_SM_BOLD: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  DISPLAY_SM_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: '600',
    letterSpacing: 0,
  },
  DISPLAY_SM_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: '500',
    letterSpacing: 0,
  },
  DISPLAY_SM_MEDIUM_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: '500',
    letterSpacing: 0,
  },
  DISPLAY_SM_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  DISPLAY_XS_BOLD: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  DISPLAY_XS_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: '600',
    letterSpacing: 0,
  },
  DISPLAY_XS_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: '500',
    letterSpacing: 0,
  },
  DISPLAY_XS_MEDIUM_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: '500',
    letterSpacing: 0,
  },
  DISPLAY_XS_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_XL_BOLD: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_XL_BOLD_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_XL_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_XL_SEMIBOLD_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_XL_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_XL_MEDIUM_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_XL_NORMAL_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_XL_NORMAL_UNDERLINED: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_XL_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_LG_BOLD: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_LG_BOLD_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_LG_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_LG_SEMIBOLD_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_LG_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_LG_MEDIUM_UNDERLINED: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_LG_MEDIUM_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_LG_NORMAL_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_LG_REGULAR_UNDERLINED: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_LG_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_MD_BOLD: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_MD_BOLD_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_MD_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_MD_SEMIBOLD_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_MD_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_MD_MEDIUM_UNDERLINED: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_MD_MEDIUM_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_MD_NORMAL_ITALIC: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_MD_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_MD_NORMAL_UNDERLINED: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_SM_BOLD: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_SM_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_SM_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_SM_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_SM_NORMAL_UNDERLINED: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  TEXT_XS_BOLD: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  TEXT_XS_SEMIBOLD: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: '600',
    letterSpacing: 0,
  },
  TEXT_XS_MEDIUM: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: 0,
  },
  TEXT_XS_NORMAL: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
}
